<template>
  <div id="agenda" class="container flex justify-center">
    <div class="flex flex-row flex-wrap w-full lg:w-4/5 py-8 sm:py-12">
      <div class="w-full sm:w-1/3">
        <div class="bg-secondary w-full h-full">
          <div class="flex flex-col justify-center align-center py-6 sm:py-12">
            <div class="h2 text-white text-center cursor-default pb-4 sm:pb-8">
              Agenda
            </div>
            <img src="~/assets/img/icons/agenda.svg" alt="Agenda">
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col justify-between sm:w-2/3 sm:pl-8">
        <div class="xs:gradient-border my-6 sm:my-0">
          <ul class="pt-2px sm:pt-0">
            <li v-for="item in events" :key="item.permalink" class="sm:mb-6">
              <button-gradient-border :icon="true" :link="`/agenda/${item.permalink}`">
                <template v-slot:content>
                  <div class="inline-block">
                    <div class="flex flex-col">
                      <div>{{ item.title }}</div>
                      <div class="text-primary-lighter font-light leading-8">
                        <time :datetime="item.date">{{ $moment(item.date).format('LL') }}</time>
                      </div>
                    </div>
                  </div>
                </template>
              </button-gradient-border>
            </li>
          </ul>
        </div>
        <btn link="/agenda">
          Meer agenda
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ButtonGradientBorder from '~/components/ButtonGradientBorder';
import Btn from '~/components/base/Btn';

export default {
  components: {Btn, ButtonGradientBorder},
  computed: {
    ...mapState({
      events: state => state.upcomingEvents,
    }),
  },
};
</script>

<style lang="sass" scoped>
/deep/ li:last-child div
  border-bottom-width: 0
</style>
